
import Swal from 'sweetalert2'

const alert=(icon,title,text)=>{
    return Swal.fire({
        icon,
        title,
        text,
    })
}

const alert_confirm=(title)=>{
    return Swal.fire({
        title,
        icon: 'question',
        iconHtml: '?',
        confirmButtonText: 'כן',
        cancelButtonText: 'לא',
        showCancelButton: true,
        showCloseButton: true
      })
}

const alert_with_text_input=async(title,inputValue)=>{
    const { value: text } = await Swal.fire({
        title,
        input: 'text',
        showCancelButton: true,
        inputValue:inputValue || '',
        inputPlaceholder: title,
        confirmButtonText:'אישור',
        cancelButtonText:'ביטול'
      })
      
      if (text) {
        return text
      }
      return false
}

export{alert,alert_confirm,alert_with_text_input}